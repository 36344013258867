import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState({ type: "", message: "" });
  const [storeInputs, setStoreInputs] = useState({});
  const [myshopifyInputs, setMyshopifyInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const backendURL = "https://dashboard.mimithemes.com";

  const styles = {
    container: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#F0A8D0",
      fontFamily: "Arial, sans-serif",
      padding: "20px", // Padding for responsiveness
    },
    header: {
      width: "100%",
      backgroundColor: "#2E2A39",
      padding: "10px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#fff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      position: "fixed",
      top: "0",
      zIndex: "1000",
    },
    logo: {
      display: "flex",
      alignItems: "center",
    },
    logoImage: {
      width: "150px", // Logo image size
      height: "auto",
    },
    navContainer: {
      // flex: 1, // Fill remaining space
      display: "flex",
      gap: "30px",
    },
    nav: {
      display: "flex",
      gap: "20px",
    },
    navLink: {
      color: "#EE66A6",
      textDecoration: "none",
      fontSize: "15px", // Compact font size
      fontWeight: "bold", // Bold for dashboard emphasis
      cursor: "pointer",
    },
    navLinkHover: {
      color: "#000", // Hover color for active look
    },
    mobileNavHidden: {
      display: "none",
    },
    mobileNavVisible: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "60px",
      left: "0",
      width: "100%",
      backgroundColor: "#2E2A39",
      padding: "10px",
      gap: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
    },
    card: {
      width: "100%",
      maxWidth: "400px", // Restricts the width for a clean design
      backgroundColor: "#2E2A39", // Darker card background for contrast
      padding: "40px", // Increased padding for breathing space
      borderRadius: "12px", // More rounded corners for modern look
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)", // Elevation effect
      color: "#fff",
      marginTop: "80px", // Ensures space below the fixed header
    },
    headerContainer: {
      textAlign: "center",
      marginBottom: "20px",
    },
    mainTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: "0",
      color: "#fff",
    },
    subtitle: {
      fontSize: "14px",
      color: "#aaa",
      marginTop: "10px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "20px", // Added spacing between form elements
    },
    label: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#aaa", // Subtle text color
      marginBottom: "8px", // Balanced spacing
    },
    input: {
      width: "100%",
      padding: "12px",
      fontSize: "16px",
      border: "1px solid #333",
      borderRadius: "6px",
      backgroundColor: "#222", // Matches card background
      color: "#fff",
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#EE66A6",
      border: "none",
      borderRadius: "6px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      boxShadow: "0 4px 10px rgba(238, 102, 166, 0.4)", // Button glow effect
      marginTop: "10px",
    },
    buttonHover: {
      backgroundColor: "#612340",
    },
    // Notice Message 
    noticeCard: {
      width: "100%",
      maxWidth: "400px",
      backgroundColor: "#2E2A39", // Slightly different color for contrast
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow
      color: "#fff",
      margin: "20px 0", // Spacing between cards
      textAlign: "center",
    },
    noticeTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#000", // Add a standout color for the title
      textTransform: "uppercase", // Converts text to uppercase

    },
    noticeText: {
      fontSize: "14px",
      color: "#000",
    },
    hamburger: {
      display: "none", // Hidden by default
      fontSize: "24px",
      color: "#fff",
      cursor: "pointer",
    },
    mobileNav: {
      display: "none", // Hidden by default for larger screens
      flexDirection: "column",
      gap: "20px",
      position: "absolute",
      top: "60px",
      left: "0",
      width: "100%",
      backgroundColor: "#2E2A39",
      padding: "20px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
    },
    mobileNavVisible: {
      display: "flex", // Display when toggled
    },
    welcomeCard: {
      textAlign: "center",
      backgroundColor: "#2E2A39",
      padding: "30px",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      color: "#fff",
      margin: "70px auto",
      maxWidth: "500px",
    },
    welcomeTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#EE66A6",
    },
    welcomeMessage: {
      fontSize: "16px",
      color: "#ccc",
      marginTop: "10px",
    },
    productsSection: {
      marginTop: "30px",
      padding: "20px",
      backgroundColor: "#2E2A39",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      color: "#fff",
      maxWidth: "1000px",
    },

    productsHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },

    productGrid: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    productCard: {
      backgroundColor: "#222",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      color: "#fff",
      wordBreak: "break-all", // Add this here too if necessary
      overflowWrap: "anywhere", // Ensure text wraps in the container
    },

    productHeader: {
      display: "flex",
      justifyContent: "space-between",
    },

    productTitle: {
      fontSize: "18px",
      fontWeight: "bold",
    },

    productMeta: {
      fontSize: "14px",
      color: "#ccc",
      wordBreak: "break-all", // Breaks long words into multiple lines
      overflowWrap: "anywhere", // Ensures wrapping even for long tokens
      overflow: "hidden", // Prevents overflow
      textOverflow: "ellipsis", // Adds "..." to indicate truncated text
    },
    logoutButton: {
      padding: "10px 20px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#EE66A6",
      border: "none",
      borderRadius: "6px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      boxShadow: "0 4px 10px rgba(238, 102, 166, 0.4)", // Glow effect
      marginLeft: "20px",
    },

    logoutButtonHover: {
      backgroundColor: "#d1558f",
    },
    subscriptionsTitle: {
      fontSize: "20px", // Match size of dashboard title
      fontWeight: "bold", // Make it bold
      color: "#EE66A6", // Match color of the title
      textAlign: "center",
      padding: "10px 0",
    },
    subscriptionsCard: {
      backgroundColor: "#2E2A39", // Keep the same card background
      padding: "20px",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      margin: "20px auto",
      maxWidth: "600px",
    },


  };

  const showNotification = (type, message) => {
    setNotification({ type, message });

    toast(message);
    setTimeout(() => setNotification({ type: "", message: "" }), 5000);
  };

  const handleVerifyToken = async (savedToken) => {
    try {
      const response = await axios.post(
        `${backendURL}/verify-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${savedToken || token}`,
          },
        }
      );
      showNotification("success", `Welcome back, ${response.data.user.email}!`);
      setToken(savedToken || token);
      getdata(savedToken || token);
    } catch (error) {
      setToken("");
      localStorage.removeItem("authToken");
      showNotification("error", error.response?.data?.error || "Session expired. Please login again.");
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem("authToken");
    if (savedToken) {
      handleVerifyToken(savedToken);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${backendURL}/login`, {
        email,
        password,
      });
      const newToken = response.data.token;
      setToken(newToken);
      localStorage.setItem("authToken", newToken);
      showNotification("success", "Successfully logged in!");
      getdata(newToken);
    } catch (error) {
      showNotification("error", error.response?.data?.error || "Invalid credentials");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setToken("");
    localStorage.removeItem("authToken");
    showNotification("success", "Successfully logged out");
    setData([]);
  };

  const getdata = async (savedToken) => {
    try {
      const response = await axios.post(
        `${backendURL}/getdata`,
        {},
        {
          headers: {
            Authorization: `Bearer ${savedToken || token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      showNotification("error", "Failed to fetch data");
      setData([]);
    }
  };

  const updatestore = async (store, myshopify, id) => {
    try {
      await axios.post(
        `${backendURL}/updatestore`,
        {
          store: store,
          myshopify: myshopify,
          id: id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showNotification("success", "Store updated successfully");
      getdata(token);
    } catch (error) {
      showNotification("error", "Failed to update store");
    }
  };

  const handleInputChange = (id, value) => {
    setStoreInputs((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const handleInputMyshopifyChange = (id, value) => {
    setMyshopifyInputs((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSendData = (id) => {
    const storeValue = storeInputs[id];
    const myshopifyValue = myshopifyInputs[id];
    updatestore(storeValue, myshopifyValue, id);
  };

  const NotificationComponent = ({ type, message }) => {
    if (!message) return null;

    const notificationStyle = {
      ...styles.notification,
      ...(type === "success" ? styles.successNotification : styles.errorNotification),
    };

    return (
      <div style={notificationStyle}>
        {message}
      </div>
    );
  };


  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        {/* Logo */}
        <div style={styles.logo}>
          <img
            src="https://cdn.shopify.com/s/files/1/0707/2917/1172/files/MIMITHEMES.png?v=1731869383"
            alt="Logo"
            style={styles.logoImage}
          />
        </div>

        {/* Navigation Menu */}
        <nav style={styles.nav}>

          {/* Logout Button - Only Visible When Logged In */}
          {token && (
            <button onClick={handleLogout} style={styles.logoutButton}>
              Sign out
            </button>
          )}
        </nav>
      </header>
      {/* Login Page Message */}
      {!token && (
        <div className="login-message">
          <h2>Access is restricted To Verified Customers.</h2>
          <p>
            Please note that your login credentials for MiMiTHEMES Dashboard are separate from your Shopify account login.
          </p>
        </div>
      )}

      {/* Conditional rendering based on token */}
      {!token ? (
        <div style={styles.card}>
          <NotificationComponent type={notification.type} message={notification.message} />
          <div style={styles.headerContainer}>
            <h1 style={styles.mainTitle}>MiMiTHEMES AREA</h1>
            <p style={styles.subtitle}>Enter your credentials to access your account</p>
          </div>
          <form onSubmit={handleLogin} style={styles.form}>
            <div>
              <label htmlFor="email" style={styles.label}>
                Email Address:
              </label>
              <input
                id="email"
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
                aria-label="Email address input"
              />
            </div>
            <div>
              <label htmlFor="password" style={styles.label}>
                Password:
              </label>
              <input
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <button
              type="submit"
              style={{
                ...styles.button,
                ...(isLoading && { cursor: "not-allowed", opacity: 0.8 }),
              }}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Sign In"}
            </button>
          </form>
        </div>
      ) : (
        <div>
          {/* Welcome Card */}
          <div style={styles.welcomeCard}>
            <h1 style={styles.welcomeTitle}>Welcome Back To MiMiThemes Dashboard</h1>
            <p style={styles.welcomeMessage}>
              Manage your themes licenses here.
            </p>
          </div>

          {/* Products Section */}
          <div style={styles.productsSection}>
            <div style={styles.subscriptionsCard}>
              <h2 style={styles.subscriptionsTitle}>Subscriptions Details</h2>
            </div>
            <div style={styles.productGrid}>
              {data?.products?.map((product) => (
                <div key={product.id} style={styles.productCard}>
                  <div style={styles.productHeader}>
                    <div>
                      <h3 style={styles.productTitle}>
                        {product.title || "Untitled"}
                      </h3>
                      <p style={styles.productMeta}>
                        <b>Subscription :</b>{product.type || "N/A"}
                      </p>
                      <p style={styles.productMeta}>
                      <b>Token :</b><CopyToClipboard text={product.unique_key}
                          onCopy={() => { toast('Copied'); }}>
                          <button style={{
    background: "#222222",
    color: "#ee66a6",
    border: "1px solid #2e2b39",
    borderRadius: "5px",
    padding: "10px",
    marginTop: "10px",
  }} > {product.unique_key || "N/A"}</button>
                        </CopyToClipboard>
                      </p>
                    </div>
                    {product.store && (
                      <button
                        onClick={() => window.open(product.link, "_blank")}
                        style={{ ...styles.button, ...styles.primaryButton }}
                      >
                        Download .zip Theme
                      </button>
                    )}
                  </div>
                  <div style={styles.inputGroup}>
                    <input
                      style={{ ...styles.input, flex: 1 }}
                      placeholder="Enter your Shopify store main domain ex : example.com"
                      value={storeInputs[product.id] || product.store || ""}
                      onChange={(e) => handleInputChange(product.id, e.target.value)}
                    />
                    <input
                      style={{ ...styles.input, flex: 1 }}
                      placeholder="Enter Myshopify URL ex : your-shop-name.myshopify.com"
                      value={myshopifyInputs[product.id] || product.store || ""}
                      onChange={(e) => handleInputMyshopifyChange(product.id, e.target.value)}
                    />
                    <button
                      onClick={() => handleSendData(product.id)}
                      style={{ ...styles.button, ...styles.primaryButton }}
                    >
                      Link Your Store
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>

  );
};

export default App;